import {SUCCESS_POSTFIX} from 'services/wamp/actions/types';

const prefix = 'HOMEWORK_STATUS/';

export const REQUEST_DELETE_HOMEWORK = prefix + 'REQUEST_DELETE_HOMEWORK';
export const REQUEST_DELETE_HOMEWORK_SUCCESS = REQUEST_DELETE_HOMEWORK + SUCCESS_POSTFIX;

export const REQUEST_CHANGE_HOMEWORK_STATUS = prefix + 'REQUEST_CHANGE_HOMEWORK_STATUS';
export const REQUEST_CHANGE_HOMEWORK_STATUS_SUCCESS =
  REQUEST_CHANGE_HOMEWORK_STATUS + SUCCESS_POSTFIX;

export const REQUEST_REACTIVATE_HOMEWORK = prefix + 'REQUEST_REACTIVATE_HOMEWORK';
export const REQUEST_REACTIVATE_HOMEWORK_SUCCESS = REQUEST_REACTIVATE_HOMEWORK + SUCCESS_POSTFIX;

export const REQUEST_PUBLISH_DRAFT = prefix + 'REQUEST_PUBLISH_DRAFT';
export const REQUEST_PUBLISH_DRAFT_SUCCESS = REQUEST_PUBLISH_DRAFT + SUCCESS_POSTFIX;

export const REQUEST_HOMEWORK_CHECKED_NOTIFICATION =
  prefix + 'REQUEST_HOMEWORK_CHECKED_NOTIFICATION';
export const REQUEST_HOMEWORK_DONE_NOTIFICATION = prefix + 'REQUEST_HOMEWORK_DONE_NOTIFICATION';

export const REQUEST_HOMEWORK_DOC_INFO = prefix + 'REQUEST_HOMEWORK_DOC_INFO';
export const REQUEST_HOMEWORK_DOC_PAGES_INFO = prefix + 'REQUEST_HOMEWORK_DOC_PAGES_INFO';
export const REQUEST_HOMEWORK_EXERCISES_INFO = prefix + 'REQUEST_HOMEWORK_EXERCISES_INFO';
