import {type Dispatch} from 'redux-axios-middleware';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {type AppState} from 'store/interface';
import {type HomeworkStatus} from 'common/enums';

import {
  REQUEST_CHANGE_HOMEWORK_STATUS,
  REQUEST_DELETE_HOMEWORK,
  REQUEST_HOMEWORK_CHECKED_NOTIFICATION,
  REQUEST_HOMEWORK_DOC_INFO,
  REQUEST_HOMEWORK_DOC_PAGES_INFO,
  REQUEST_HOMEWORK_EXERCISES_INFO,
  REQUEST_HOMEWORK_DONE_NOTIFICATION,
  REQUEST_PUBLISH_DRAFT,
  REQUEST_REACTIVATE_HOMEWORK
} from './actionTypes';
import {
  type HomeworkDocumentPagesInfo,
  type HomeworkDocumentsInfo,
  type HomeworkExercisesInfo
} from './interface';

export const deleteHomework = (
  courseInstanceId: number,
  homeworkId: string
): AxiosRequestAction => ({
  type: REQUEST_DELETE_HOMEWORK,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}`
    }
  }
});

export const changeHomeworkStatus = (
  courseInstanceId: number,
  homeworkId: string,
  status: HomeworkStatus
): AxiosRequestAction => ({
  type: REQUEST_CHANGE_HOMEWORK_STATUS,
  payload: {
    client: 'v2',
    request: {
      method: 'put',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}`,
      data: {status}
    }
  }
});

export const publishDraft = (
  courseInstanceId: number,
  homeworkId: string,
  comment?: string
): AxiosRequestAction => ({
  type: REQUEST_PUBLISH_DRAFT,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}`,
      data: {comment}
    }
  }
});

export const homeworkCheckedNotification = (
  courseInstanceId: number,
  comment: string,
  url?: string
): AxiosRequestAction => ({
  type: REQUEST_HOMEWORK_CHECKED_NOTIFICATION,
  payload: {
    request: {
      method: 'post',
      url: `v1/classroom/course-instance/${courseInstanceId}/homework-checked`,
      data: {comment, url}
    }
  }
});

export const homeworkDoneNotification = (
  courseInstanceId: number,
  comment: string,
  url?: string
): AxiosRequestAction => ({
  type: REQUEST_HOMEWORK_DONE_NOTIFICATION,
  payload: {
    request: {
      method: 'post',
      url: `v1/classroom/course-instance/${courseInstanceId}/homework`,
      data: {comment, url}
    }
  }
});

export const reactivateHomework = (
  courseInstanceId: number,
  homeworkId: string
): AxiosRequestAction => ({
  type: REQUEST_REACTIVATE_HOMEWORK,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}`
    }
  }
});

const homeworkDocInfoAction = (
  courseInstanceId: number,
  homeworkId: string
): AxiosRequestAction => ({
  type: REQUEST_HOMEWORK_DOC_INFO,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document`
    }
  }
});

const homeworkDocPagesInfoAction = (
  courseInstanceId: number,
  homeworkId: string
): AxiosRequestAction => ({
  type: REQUEST_HOMEWORK_DOC_PAGES_INFO,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document-page`
    }
  }
});

const homeworkExercisesInfoAction = (
  courseInstanceId: number,
  homeworkId: string
): AxiosRequestAction => ({
  type: REQUEST_HOMEWORK_EXERCISES_INFO,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/exercise`
    }
  }
});

export const requestHomeworkDocsInfo =
  (courseInstanceId: number, homeworkId: string) =>
  async (dispatch: Dispatch<AxiosRequestAction, AppState>): Promise<HomeworkDocumentsInfo> => {
    const responseAction: AxiosResponseAction<HomeworkDocumentsInfo> = await dispatch(
      homeworkDocInfoAction(courseInstanceId, homeworkId)
    );
    return responseAction.payload.data;
  };

export const requestHomeworkDocPagesInfo =
  (courseInstanceId: number, homeworkId: string) =>
  async (dispatch: Dispatch<AxiosRequestAction, AppState>): Promise<HomeworkDocumentPagesInfo> => {
    const responseAction: AxiosResponseAction<HomeworkDocumentPagesInfo> = await dispatch(
      homeworkDocPagesInfoAction(courseInstanceId, homeworkId)
    );
    return responseAction.payload.data;
  };

export const requestHomeworkExercisesInfo =
  (courseInstanceId: number, homeworkId: string) =>
  async (dispatch: Dispatch<AxiosRequestAction, AppState>): Promise<HomeworkExercisesInfo> => {
    const responseAction: AxiosResponseAction<HomeworkExercisesInfo> = await dispatch(
      homeworkExercisesInfoAction(courseInstanceId, homeworkId)
    );
    return responseAction.payload.data;
  };
