import React, {type FC, useCallback, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {type Homework, type HomeworkWithOverview} from 'store/interface';
import Card from 'components/Cards/Card';

import {deleteHomework, publishDraft, reactivateHomework} from './actions';
import {CardModals} from './Modals/CardModals';
import HomeworkCardContent from './HomeworkCardContent';
import {type HomeworkExtra} from './interface';
import {HomeworkPhase, resolveHomeworkPhase} from './utils';

interface Props {
  expanded?: boolean;
  isStudent: boolean;
  homework: HomeworkWithOverview;
  CardBodyDecorator?: FC;
  homeworkExtra: HomeworkExtra | undefined;
  homeworkPathMapper: (homework: Homework) => string;
  onChangeHomeworkStatus: (homeworkId: string, courseInstanceId?: number) => void;
  userTimeZone?: string;
}

export const CardContainer: FC<Props> = ({
  expanded,
  isStudent,
  homework,
  CardBodyDecorator,
  homeworkExtra,
  homeworkPathMapper,
  onChangeHomeworkStatus,
  userTimeZone
}) => {
  const intl = useIntl();

  const [action, setAction] = useState<string | undefined>();
  const [accepting, setAccepting] = useState(false);

  const {activatedAt, completedAt, status, courseInstanceId, id} = homework;

  const phase = useMemo<HomeworkPhase>(
    () => resolveHomeworkPhase(activatedAt, completedAt, status),
    [activatedAt, completedAt, status]
  );

  const sendAction = useCallback(
    (action: string | undefined, comment?: string) => {
      switch (action) {
        case HomeworkPhase.CHECKED:
          return deleteHomework(courseInstanceId, id);
        case HomeworkPhase.NOT_ASSIGNED:
          return publishDraft(courseInstanceId, id, comment);
        case HomeworkPhase.COMPLETED:
          return reactivateHomework(courseInstanceId, id);
        default:
          return undefined;
      }
    },
    [courseInstanceId, id]
  );

  return (
    <>
      <Card
        className={expanded ? 'expanded' : undefined}
        redirectsTo={homeworkPathMapper(homework)}
      >
        <HomeworkCardContent
          renderBodyDecorator={
            CardBodyDecorator && homeworkExtra
              ? children => <CardBodyDecorator {...homeworkExtra}>{children}</CardBodyDecorator>
              : undefined
          }
          userTimeZone={userTimeZone}
          homework={homework}
          intl={intl}
          isStudent={isStudent}
          onChangeHomeworkStatus={onChangeHomeworkStatus}
          action={action}
          setAction={setAction}
          accepting={accepting}
          setAccepting={setAccepting}
          sendAction={sendAction}
        />
      </Card>

      <CardModals
        intl={intl}
        phase={phase}
        isStudent={isStudent}
        action={action}
        accepting={accepting}
        setAction={setAction}
        setAccepting={setAccepting}
        sendAction={sendAction}
      />
    </>
  );
};
