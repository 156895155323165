import React, {type FC, useCallback} from 'react';
import Scrollbars from 'react-custom-scrollbars';

import {type HomeworkDocInfo} from '../interface';
import {InfoOverlayExercises} from './InfoOverlayExercises';
import {InfoOverlayDocuments} from './InfoOverlayDocuments';

interface InfoOverlayProps {
  data: HomeworkDocInfo;
}

export const InfoOverlay: FC<InfoOverlayProps> = ({data}) => {
  const onClickScrollbars = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <Scrollbars autoHide={true} autoHeightMax={300} autoHeight={true} onClick={onClickScrollbars}>
      <div className="list">
        {data.map(({comment, title, type, pageNumber, units}, i) => (
          <React.Fragment key={i}>
            {units ? (
              <InfoOverlayExercises title={title} units={units} />
            ) : (
              <InfoOverlayDocuments
                comment={comment}
                title={title}
                type={type}
                pageNumber={pageNumber}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </Scrollbars>
  );
};
