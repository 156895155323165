import {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from '../../Icon';
import {type HomeworkExerciseUnit} from '../interface';

interface Props {
  title: string;
  units: HomeworkExerciseUnit[];
}

export const InfoOverlayExercises: FC<Props> = ({title, units}) => {
  return (
    <div className="homework-document-info">
      <div className="first-col">
        <Icon name="book" />
      </div>
      <div className="second-col">
        <div className="title">{title}</div>
        {units.map(unit => (
          <div key={unit.unitInstanceId} className="unit-list">
            <div className="page-number">
              <Icon name={unit.isRevision ? 'virc-test' : 'virc-unit'} />{' '}
              {unit.isRevision ? unit.title : `Unit ${unit.ordinal}: ${unit.title}`}
            </div>
            <div className="third-col">
              {unit.pages.map(({pageNumber, mainExercises, extraExercises}) => (
                <div className="unit-info" key={pageNumber}>
                  <span>
                    <Icon name="file-text-o" /> <span className="u-page"> Page {pageNumber}: </span>
                    <FormattedMessage
                      id="Homework.Info.Exercises.Count"
                      values={{main: mainExercises.length, extra: extraExercises.length}}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
