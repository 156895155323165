import React, {type FC, useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {type HomeworkDocInfoProps} from '../interface';
import {useAxiosDispatch} from '../../../hooks/redux/useAxiosDispatch';
import {requestHomeworkDocsInfo} from '../actions';
import {InfoContent} from './InfoContent';

export const DocumentsInfo: FC<
  Omit<HomeworkDocInfoProps, 'completedAt' | 'exercises' | 'documentPages'> & {
    getTooltipContainer(): HTMLElement;
  }
> = ({courseInstanceId, getTooltipContainer, documents, homeworkId}) => {
  const intl = useIntl();
  const dispatch = useAxiosDispatch();
  const request = useCallback(
    async () => await dispatch(requestHomeworkDocsInfo(courseInstanceId, homeworkId)),
    [courseInstanceId, dispatch, homeworkId]
  );

  return (
    <InfoContent
      label={<FormattedMessage id="HomeworkPage.Overview.Documents" values={{count: documents}} />}
      request={request}
      getTooltipContainer={getTooltipContainer}
      iconTitle={intl.formatMessage({id: 'HomeworkPage.Overview.Documents.Title'})}
      errorMessage={intl.formatMessage({id: 'HomeworkPage.Overview.Documents.ErrorToastr'})}
    />
  );
};
