import React, {type FC, useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAxiosDispatch} from '../../../hooks/redux/useAxiosDispatch';
import {type HomeworkDocInfoProps} from '../interface';
import {requestHomeworkDocPagesInfo} from '../actions';
import {InfoContent} from './InfoContent';

type Props = Omit<HomeworkDocInfoProps, 'completedAt' | 'exercises' | 'documents'> & {
  getTooltipContainer(): HTMLElement;
};

export const PagesInfo: FC<Props> = ({
  getTooltipContainer,
  courseInstanceId,
  homeworkId,
  documentPages
}) => {
  const intl = useIntl();
  const dispatch = useAxiosDispatch();
  const request = useCallback(
    async () => await dispatch(requestHomeworkDocPagesInfo(courseInstanceId, homeworkId)),
    [courseInstanceId, dispatch, homeworkId]
  );

  return (
    <InfoContent
      label={
        <FormattedMessage
          id="HomeworkPage.Overview.DocumentPages"
          values={{count: documentPages}}
        />
      }
      request={request}
      getTooltipContainer={getTooltipContainer}
      iconTitle={intl.formatMessage({id: 'HomeworkPage.Overview.DocumentPages.Title'})}
      errorMessage={intl.formatMessage({id: 'HomeworkPage.Overview.DocumentPages.ErrorToastr'})}
    />
  );
};
