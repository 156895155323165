import React, {type FC, useCallback, useRef} from 'react';
import classNames from 'classnames';

import {type HomeworkDocInfoProps} from './interface';
import {PagesInfo} from './Info/PagesInfo';
import {DocumentsInfo} from './Info/DocumentsInfo';
import {ExercisesInfo} from './Info/ExercisesInfo';

const Overview: FC<HomeworkDocInfoProps> = ({
  completedAt,
  courseInstanceId,
  exercises,
  documents,
  documentPages,
  homeworkId
}) => {
  const overviewNode = useRef<HTMLDivElement | null>(null);

  const getTooltipContainer = useCallback<() => HTMLElement>(
    () => document.querySelector('.homework-list-content .cards') || document.body,
    []
  );

  return (
    <div className={classNames('overview', {opaque: completedAt})} ref={overviewNode}>
      {!exercises ? null : (
        <ExercisesInfo
          courseInstanceId={courseInstanceId}
          exercises={exercises}
          getTooltipContainer={getTooltipContainer}
          homeworkId={homeworkId}
        />
      )}
      {!documentPages ? null : (
        <PagesInfo
          courseInstanceId={courseInstanceId}
          documentPages={documentPages}
          getTooltipContainer={getTooltipContainer}
          homeworkId={homeworkId}
        />
      )}
      {!documents ? null : (
        <DocumentsInfo
          courseInstanceId={courseInstanceId}
          documents={documents}
          getTooltipContainer={getTooltipContainer}
          homeworkId={homeworkId}
        />
      )}
    </div>
  );
};

export default Overview;
