import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {push} from 'store/router';

import {type RequestOptions, type StatusOption} from './interface';

const useFilter = (
  search: string,
  requestOptions: RequestOptions,
  searchChangedCallback: () => void
) => {
  const dispatch = useDispatch();
  const applyStatusesFilter = useCallback<(s: StatusOption[]) => void>(
    statuses => {
      const searchParams = new URLSearchParams(search);
      searchParams.set('hideCompleted', requestOptions.hideCompleted ? 'true' : 'false');
      searchParams.set(
        'statuses',
        statuses && statuses.length ? statuses.map(s => s.value).join(',') : ''
      );
      const searchParamsString = searchParams.toString();
      searchChangedCallback();
      dispatch(push({search: searchParamsString}));
    },
    [dispatch, search, requestOptions.hideCompleted, searchChangedCallback]
  );
  const applyHideCompletedFilter = useCallback<(hc?: boolean) => void>(
    hideCompleted => {
      const searchParams = new URLSearchParams(search);
      searchParams.set(
        'statuses',
        requestOptions.statuses && requestOptions.statuses.length
          ? requestOptions.statuses.join(',')
          : ''
      );
      searchParams.set('hideCompleted', hideCompleted ? 'true' : 'false');
      const searchParamsString = searchParams.toString();
      searchChangedCallback();
      dispatch(push({search: searchParamsString}));
    },
    [dispatch, requestOptions.statuses, search, searchChangedCallback]
  );
  return {
    applyStatusesFilter,
    applyHideCompletedFilter
  };
};

export default useFilter;
