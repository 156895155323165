import React, {useCallback} from 'react';
import * as Yup from 'yup';
import {type FormikBag, type InjectedFormikProps, withFormik} from 'formik';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import Modal from 'react-bootstrap/lib/Modal';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';

const maxCommentLength = 4096;
const textareaRows = 5;

const messages = defineMessages({
  CommentTooLong: {
    id: 'Homework.Modal.ChangeStatus.Comment.MaxLength'
  },
  CommentPlaceholder: {
    id: 'Homework.Modal.ChangeStatus.Placeholder'
  }
});

interface Values {
  comment: string;
}

interface OwnProps {
  handleSubmit: (comment: string) => void;
  initialComment?: string;
  submittingForm?: boolean;
}

type Props = OwnProps & WrappedComponentProps;

type FormProps = InjectedFormikProps<Props, Values>;

const SendHomeworkNotificationForm: React.FC<FormProps> = ({
  errors,
  touched,
  handleSubmit,
  intl: {formatMessage},
  handleBlur,
  values,
  handleChange,
  setFieldTouched,
  children,
  submittingForm
}) => {
  const onChange = useCallback(
    (e: React.FormEvent<FormControl>) => {
      // set field as touched so that validation message can appear before input blurred
      setFieldTouched('comment');
      handleChange(e);
    },
    [setFieldTouched, handleChange]
  );
  return (
    <form onSubmit={handleSubmit}>
      <Modal.Body>
        <FormGroup
          controlId="comment"
          validationState={errors.comment && touched.comment ? 'error' : undefined}
        >
          <FormControl
            name="comment"
            bsSize="lg"
            componentClass="textarea"
            placeholder={formatMessage(messages.CommentPlaceholder)}
            rows={textareaRows}
            value={values.comment}
            autoComplete="off"
            onBlur={handleBlur}
            onChange={onChange}
            disabled={submittingForm}
          />
          <div className="help-block error">{errors.comment}</div>
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>{children}</Modal.Footer>
    </form>
  );
};

const mapPropsToValues = (props: Props): Values => ({
  comment: props.initialComment || ''
});

const handleSubmit = (values: Values, {props}: FormikBag<Props, Values>) => {
  props.handleSubmit(values.comment);
};

const validationSchema = ({intl: {formatMessage}}: FormProps) =>
  Yup.object().shape({
    comment: Yup.string()
      .trim()
      .max(maxCommentLength, formatMessage(messages.CommentTooLong, {length: maxCommentLength}))
  });

const WithFormik = withFormik({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  validateOnChange: true
})(SendHomeworkNotificationForm);

export default injectIntl(WithFormik);
