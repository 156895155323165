import React, {type FC, type ReactElement} from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import Icon from '../../Icon';
import SendHomeworkNotificationForm from './SendHomeworkNotificationForm';
import Loader from '../../Loader';

import './SendHomeworkNotificationModal.scss';

interface Props {
  show?: boolean;
  headerText?: string | ReactElement;
  isLoading: boolean;
  comment?: string;
  changeComment?: string;
  onAccept: (comment: string) => void;
  onHide: () => void;
}

export const HomeworkModal: FC<Props> = ({show, headerText, isLoading, onAccept, onHide}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      className="send-homework-notification-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <Icon name="file-text-o" />

          {headerText}

          <a onClick={onHide}>
            <Icon name="pc-close" tag="i" />
          </a>
        </Modal.Title>
      </Modal.Header>
      {
        <SendHomeworkNotificationForm handleSubmit={onAccept} submittingForm={isLoading}>
          <Button
            bsStyle="default"
            className="btn-transparent"
            type="reset"
            bsSize="sm"
            onClick={onHide}
            disabled={isLoading}
          >
            <FormattedMessage id="Common.Cancel" />
          </Button>
          <Button
            className={classNames('submit-comment-button', {submitting: isLoading})}
            bsStyle="primary"
            bsSize="sm"
            type="submit"
            disabled={isLoading}
          >
            <div className="loader-button-positioning-helper">
              <Loader shouldRender={isLoading} />
            </div>
            <FormattedMessage id="Common.Send" />
          </Button>
        </SendHomeworkNotificationForm>
      }
    </Modal>
  );
};
