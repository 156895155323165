import React, {type FC, useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import {useAxiosDispatch} from '../../../hooks/redux/useAxiosDispatch';
import {type HomeworkDocInfoProps} from '../interface';
import {requestHomeworkExercisesInfo} from '../actions';
import {InfoContent} from './InfoContent';

type Props = Omit<HomeworkDocInfoProps, 'completedAt' | 'documents'> & {
  getTooltipContainer(): HTMLElement;
};

export const ExercisesInfo: FC<Props> = ({
  getTooltipContainer,
  courseInstanceId,
  homeworkId,
  exercises
}) => {
  const intl = useIntl();
  const dispatch = useAxiosDispatch();
  const request = useCallback(
    async () => await dispatch(requestHomeworkExercisesInfo(courseInstanceId, homeworkId)),
    [courseInstanceId, dispatch, homeworkId]
  );

  return (
    <InfoContent
      label={<FormattedMessage id="HomeworkPage.Overview.Exercises" values={{count: exercises}} />}
      request={request}
      getTooltipContainer={getTooltipContainer}
      iconTitle={intl.formatMessage({id: 'HomeworkPage.Overview.Exercises.Title'})}
      errorMessage={intl.formatMessage({id: 'HomeworkPage.Overview.Exercises.ErrorToastr'})}
    />
  );
};
