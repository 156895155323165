import React, {type FC, type FormEvent, useCallback, memo} from 'react';
import {FormattedMessage, type IntlShape} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import ReactSelect from 'components/ReactSelect/ReactSelect';

import {type RequestOptions, type StatusOption} from './interface';
import {selectMap} from './utils';
import i18n from './i18n';
import './Filter.scss';

interface Props {
  applyHideCompletedFilter: (hideCompleted?: boolean) => void;
  applyStatusesFilter: (statuses: StatusOption[]) => void;
  intl: IntlShape;
  requestOptions: RequestOptions;
}

const allStatuses: StatusOption[] = [
  {label: 'given', value: 0},
  {label: 'done', value: 1},
  {label: 'checked', value: 2}
];

const Filter: FC<Props> = memo(
  ({applyHideCompletedFilter, applyStatusesFilter, children, intl, requestOptions}) => {
    const isValueSelected = useCallback<(o: StatusOption) => boolean>(
      option =>
        !requestOptions.statuses ? false : requestOptions.statuses.some(s => s === option.value),
      [requestOptions.statuses]
    );

    const onChange = useCallback<(o: StatusOption[]) => void>(
      options => {
        applyStatusesFilter(options);
      },
      [applyStatusesFilter]
    );

    const toggleCheckbox = useCallback<(e: FormEvent<Checkbox>) => void>(
      e => {
        applyHideCompletedFilter(!e.currentTarget['checked']);
      },
      [applyHideCompletedFilter]
    );

    return (
      <div className="homework-filter">
        <div className="multiselect-container">
          <div className="multiselect-container-title">
            {intl.formatMessage({id: 'CoursebookLibrary.CoursebookStatus'})}:
          </div>
          <ReactSelect
            options={allStatuses.map(({label, value}) => ({
              label: intl.formatMessage(selectMap[label]),
              value
            }))}
            onChange={onChange}
            isValueSelected={isValueSelected}
            placeholderMessageDescriptor={i18n.statusFilterPlaceholder}
          />
        </div>
        {children}
        <div className="checkbox-container">
          <FormGroup controlId="send-email">
            <Checkbox onChange={toggleCheckbox} checked={!requestOptions.hideCompleted}>
              <FormattedMessage id="Homework.Filter.ShowCompleted" />
            </Checkbox>
          </FormGroup>
        </div>
      </div>
    );
  }
);

export default Filter;
