import React, {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useMemo
} from 'react';
import classNames from 'classnames';
import Label from 'react-bootstrap/lib/Label';
import {FormattedDate, FormattedMessage, FormattedTime, type IntlShape} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import {type HomeworkWithOverview} from 'store/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {
  buttonMap,
  HomeworkPhase,
  labelMap,
  mapPhaseToDescriptor,
  resolveHomeworkPhase
} from './utils';
import i18n from './i18n';
import Icon from '../Icon';
import './HomeworkCardContent.scss';
import Overview from './Overview';

interface Props {
  renderBodyDecorator?: (children: ReactNode) => ReactNode;
  homework: HomeworkWithOverview;
  intl: IntlShape;
  isStudent: boolean;
  action: string | undefined;
  accepting: boolean;
  setAction: Dispatch<SetStateAction<string | undefined>>;
  setAccepting: Dispatch<SetStateAction<boolean>>;
  sendAction: (action: string | undefined, comment?: string) => AxiosRequestAction | undefined;
  renderCourseTitle?: () => ReactNode;
  renderUser?: () => ReactNode;
  onChangeHomeworkStatus: (homeworkId: string, courseInstanceId: number) => void;
  userTimeZone?: string;
}

const emptyFn = () => {};

const Title: FC<{
  activatedAt: string | null;
  completedAt: string | null;
  userTimeZone?: string;
}> = ({activatedAt, completedAt, userTimeZone}) => (
  <h4 className={classNames('title', {opaque: !activatedAt || completedAt})}>
    {activatedAt ? (
      <>
        <FormattedDate
          value={activatedAt}
          day="numeric"
          month="numeric"
          year="2-digit"
          timeZone={userTimeZone}
        />{' '}
        <FormattedTime value={activatedAt} timeZone={userTimeZone} />
      </>
    ) : (
      <FormattedMessage id="HomeworkPage.Status.Draft" />
    )}
  </h4>
);

function HomeworkCardContent({
  onChangeHomeworkStatus,
  renderBodyDecorator,
  homework: {
    id,
    activatedAt,
    completedAt,
    courseInstanceId,
    overview: {documents, exercises, documentPages},
    status
  },
  intl,
  isStudent,
  setAction,
  userTimeZone
}: PropsWithChildren<Props>) {
  const phase = useMemo<HomeworkPhase>(
    () => resolveHomeworkPhase(activatedAt, completedAt, status),
    [activatedAt, completedAt, status]
  );

  const label = useMemo<string>(
    () => intl.formatMessage(mapPhaseToDescriptor(labelMap)(phase)),
    [intl, phase]
  );

  const overview = (
    <Overview
      courseInstanceId={courseInstanceId}
      completedAt={completedAt}
      documents={documents}
      documentPages={documentPages}
      exercises={exercises}
      homeworkId={id}
    />
  );

  const teacherButtonMessageDescriptor = useMemo(
    () => mapPhaseToDescriptor(buttonMap)(phase),
    [phase]
  );

  const buttonOnClick = useCallback(
    (e: React.MouseEvent<Button>) => {
      e.stopPropagation();
      e.preventDefault();
      if (isStudent || [HomeworkPhase.TO_CHECK, HomeworkPhase.ASSIGNED].includes(phase)) {
        onChangeHomeworkStatus(id, courseInstanceId);
        return;
      }
      setAction(phase);
    },
    [isStudent, phase, setAction, onChangeHomeworkStatus, id, courseInstanceId]
  );

  const deleteClick = useMemo(() => {
    if (isStudent || ![HomeworkPhase.NOT_ASSIGNED].includes(phase)) {
      return emptyFn;
    }

    return (e: React.MouseEvent<Button>) => {
      e.stopPropagation();
      e.preventDefault();
      setAction(HomeworkPhase.CHECKED);
    };
  }, [setAction, isStudent, phase]);

  [HomeworkPhase.ASSIGNED, HomeworkPhase.CHECKED].includes(phase);

  return (
    <div className="homework-card-content">
      <div className="card-header">
        <Title activatedAt={activatedAt} completedAt={completedAt} userTimeZone={userTimeZone} />
        <Label className={`${phase}`}>{label}</Label>
      </div>
      <div className="card-body">
        {renderBodyDecorator ? renderBodyDecorator(overview) : overview}
      </div>
      <div className="card-footer">
        {deleteClick === emptyFn ? null : (
          <Button
            onClick={deleteClick}
            className="btn-circle"
            title={intl.formatMessage({id: 'Common.Delete'})}
          >
            <Icon name="trash" />
          </Button>
        )}
        {isStudent && phase !== HomeworkPhase.ASSIGNED ? null : (
          <Button onClick={buttonOnClick}>
            {isStudent
              ? intl.formatMessage(i18n.homeworkActionSubmit)
              : intl.formatMessage(teacherButtonMessageDescriptor)}
          </Button>
        )}
      </div>
    </div>
  );
}

export default HomeworkCardContent;
