import React, {
  type Dispatch,
  type FC,
  type SetStateAction,
  useCallback,
  useEffect,
  useRef
} from 'react';
import {type IntlShape} from 'react-intl';

import * as toastr from 'components/toastr';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import Confirm from 'components/modals/Confirm';
import {type AxiosRequestAction} from 'services/axios/interface';

import {REQUEST_DELETE_HOMEWORK, REQUEST_REACTIVATE_HOMEWORK} from '../actionTypes';
import {HomeworkModal} from './HomeworkModal';
import {HomeworkPhase} from '../utils';
import i18n from '../i18n';

const failMessage = (actionType: string, phase: HomeworkPhase) => {
  switch (actionType) {
    case REQUEST_REACTIVATE_HOMEWORK:
      return i18n.homeworkReactivationFail;
    case REQUEST_DELETE_HOMEWORK:
      if ([HomeworkPhase.NOT_ASSIGNED, HomeworkPhase.ASSIGNED].includes(phase)) {
        return i18n.homeworkDeleteFailToast;
      }
      return i18n.homeworkCompleteFail;
    default:
      return {id: 'Homework.Draft.PublicationFailed'};
  }
};

const confirmHeader = (actionType: string, phase: HomeworkPhase) => {
  switch (actionType) {
    case REQUEST_REACTIVATE_HOMEWORK:
      return i18n.homeworkReactivationConfirmation;
    case REQUEST_DELETE_HOMEWORK:
      return [HomeworkPhase.NOT_ASSIGNED, HomeworkPhase.ASSIGNED].includes(phase)
        ? i18n.homeworkDeleteConfirmation
        : i18n.homeworkCompleteConfirm;
    default:
      return i18n.homeworkActivateConfirmation;
  }
};

const successMessage = (actionType: string, phase: HomeworkPhase) => {
  switch (actionType) {
    case REQUEST_REACTIVATE_HOMEWORK:
      return i18n.homeworkReactivationSuccess;
    case REQUEST_DELETE_HOMEWORK:
      if ([HomeworkPhase.NOT_ASSIGNED, HomeworkPhase.ASSIGNED].includes(phase)) {
        return i18n.homeworkDeleteSuccessToast;
      }
      return i18n.homeworkCompleteSuccess;
    default:
      return {id: 'Homework.Draft.Published'};
  }
};

interface Props {
  intl: IntlShape;
  phase: HomeworkPhase;
  isStudent: boolean;
  action: string | undefined;
  accepting: boolean;
  setAction: Dispatch<SetStateAction<string | undefined>>;
  setAccepting: Dispatch<SetStateAction<boolean>>;
  sendAction: (action: string | undefined, comment?: string) => AxiosRequestAction | undefined;
}

export const CardModals: FC<Props> = ({
  intl,
  phase,
  isStudent,
  action,
  accepting,
  setAction,
  setAccepting,
  sendAction
}) => {
  const axios = useAxiosDispatch();

  const asyncAction = sendAction(action);
  const mounted = useRef(true);

  const onAccept = useCallback(
    (comment?: string) => {
      const asyncAction = sendAction(action, comment);

      if (!asyncAction || accepting) return;

      setAccepting(true);

      axios(asyncAction)
        .then(() => {
          toastr.success('', intl.formatMessage(successMessage(asyncAction.type, phase)));
        })
        .catch(() => {
          toastr.error('', intl.formatMessage(failMessage(asyncAction.type, phase)));
        })
        .finally(() => {
          if (mounted.current) {
            setAction(undefined);
            setAccepting(false);
          }
        });
    },
    [sendAction, action, accepting, setAccepting, axios, intl, phase, setAction]
  );

  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  return (
    <>
      {isStudent ? null : !!(action === HomeworkPhase.CHECKED) ? (
        <Confirm
          show={!!action}
          onAccept={onAccept}
          onDecline={() => setAction(undefined)}
          headerText={asyncAction ? intl.formatMessage(confirmHeader(asyncAction.type, phase)) : ''}
          disableButtons={accepting}
        />
      ) : (
        <HomeworkModal
          show={!!action}
          headerText={asyncAction ? intl.formatMessage(confirmHeader(asyncAction.type, phase)) : ''}
          isLoading={accepting}
          onAccept={onAccept}
          onHide={() => setAction(undefined)}
        />
      )}
    </>
  );
};
