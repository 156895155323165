import {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from '../../Icon';

interface Props {
  type: string;
  title: string;
  pageNumber?: number;
  comment: string;
}

export const InfoOverlayDocuments: FC<Props> = ({type, title, comment, pageNumber}) => {
  return (
    <div className="homework-document-info">
      <div className="first-col">
        <Icon name="file-pdf-o" />
      </div>
      <div className="second-col">
        <div className="title">{title}</div>
        <div className="page-comment">
          {pageNumber && (
            <span className="page-number">
              <Icon name="file-text-o" /> <FormattedMessage id="Common.Page" /> {pageNumber}
            </span>
          )}
          <div className="comment">{comment}</div>
        </div>
      </div>
    </div>
  );
};
