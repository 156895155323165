import React, {type FC, useCallback, useState} from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {type HomeworkDocumentsInfo} from '../interface';
import * as toastr from '../../toastr';
import Spinner from '../../Spinner';
import Icon from '../../Icon';
import {InfoOverlay} from './InfoOverlay';
import './InfoContent.scss';

interface Props {
  label: React.ReactNode;
  errorMessage: string;
  request: () => Promise<unknown>;
  iconTitle: string;
  iconName?: string;
  getTooltipContainer(): HTMLElement;
}

export const InfoContent: FC<Props> = ({
  getTooltipContainer,
  request,
  label,
  errorMessage,
  iconTitle,
  iconName = 'info-circle'
}) => {
  const [data, setData] = useState<HomeworkDocumentsInfo>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const isMounted = useIsMounted();

  const requestData = useCallback(() => {
    if (!data && !loading) {
      setLoading(true);
      request()
        .then((data: HomeworkDocumentsInfo) => {
          if (isMounted.current) {
            setData(data);
            setVisible(true);
          }
        })
        .catch(e => {
          !!errorMessage && toastr.error('', errorMessage);
        })
        .finally(() => {
          isMounted.current && setLoading(false);
        });
    }
  }, [data, errorMessage, isMounted, loading, request]);

  const onClick = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      visible ? setVisible(false) : data ? setVisible(true) : requestData();
    },
    [data, requestData, visible]
  );
  return (
    <span className="info-content">
      {label}
      <Tooltip
        overlay={loading || !data ? null : <InfoOverlay data={data} />}
        overlayClassName={classNames('info-content-tooltip', {
          invisible: !data || loading
        })}
        trigger={['click']}
        destroyTooltipOnHide={true}
        placement="bottom"
        getTooltipContainer={getTooltipContainer}
        onVisibleChange={visible => !visible && setVisible(false)}
        visible={visible}
      >
        <Button
          className={classNames('info-trigger', {visible})}
          onClick={onClick}
          title={iconTitle}
        >
          {loading ? <Spinner size={14} /> : <Icon name={iconName} />}
        </Button>
      </Tooltip>
    </span>
  );
};
