import React, {type FC, type PropsWithChildren, type ReactNode, useEffect, useRef} from 'react';
import classNames from 'classnames';
import Scrollbar from 'react-custom-scrollbars';
import {FormattedMessage} from 'react-intl';

import {type Homework, type HomeworkWithOverview} from 'store/interface';

import Cards from '../Cards/Cards';
import Loader from '../Loader';
import InfiniteScroll from '../InfiniteScroll';
import {type HomeworkExtra} from './interface';
import {CardContainer} from './CardContainer';

import './HomeworkList.scss';

interface Props {
  CardBodyDecorator?: FC;
  className?: string;
  expanded?: boolean;
  hasMore: boolean;
  homeworkExtras?: HomeworkExtra[];
  homeworkList: HomeworkWithOverview[];
  homeworkPathMapper: (homework: Homework) => string;
  isLoading: boolean;
  isStudent: boolean;
  loadMore: (pageNumber: number) => Promise<{} | void>;
  renderFilter?: () => ReactNode;
  renderHeader?: () => ReactNode;
  shouldResetLoader: boolean;
  setShouldResetLoader: (shouldResetLoader: boolean) => void;
  onChangeHomeworkStatus: (homeworkId: string, courseInstanceId?: number) => void;
  userTimeZone?: string;
}

function HomeworkList({
  CardBodyDecorator,
  className,
  expanded,
  hasMore,
  homeworkExtras,
  homeworkList,
  homeworkPathMapper,
  isLoading,
  isStudent,
  loadMore,
  renderFilter,
  renderHeader,
  shouldResetLoader,
  setShouldResetLoader,
  onChangeHomeworkStatus,
  userTimeZone
}: PropsWithChildren<Props>) {
  const scrollRef = useRef<InfiniteScroll | null>(null);

  useEffect(() => {
    if (shouldResetLoader) {
      scrollRef.current && (scrollRef.current.pageLoaded = 1);
      setShouldResetLoader(false);
    }
  });

  return (
    <div className={classNames('homework-list', className ? {[className]: true} : {})}>
      <Scrollbar autoHide={true}>
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore}
          initialLoad={false}
          useWindow={false}
          loader={<Loader key="loader-key" />}
          pageStart={1}
          threshold={50}
          ref={scrollRef}
        >
          <div className="homework-list-content" key="homework-list-content">
            {renderHeader && renderHeader()}
            {renderFilter && renderFilter()}
            {isLoading ? (
              <Loader />
            ) : !homeworkList.length ? (
              <div className="no-files">
                <FormattedMessage id="HomeworkPage.NoHomeworksFound" />
              </div>
            ) : (
              <Cards>
                {homeworkList.map(homework => {
                  const homeworkExtra = homeworkExtras?.find(he => he.id === homework.id);
                  return (
                    <CardContainer
                      userTimeZone={userTimeZone}
                      key={homework.id}
                      expanded={expanded}
                      isStudent={isStudent}
                      homework={homework}
                      CardBodyDecorator={CardBodyDecorator}
                      homeworkExtra={homeworkExtra}
                      homeworkPathMapper={homeworkPathMapper}
                      onChangeHomeworkStatus={onChangeHomeworkStatus}
                    />
                  );
                })}
              </Cards>
            )}
          </div>
        </InfiniteScroll>
      </Scrollbar>
    </div>
  );
}

export default HomeworkList;
