import React, {useCallback, useState} from 'react';
import {FormattedMessage, injectIntl, type WrappedComponentProps, defineMessages} from 'react-intl';
import {useSelector, useDispatch} from 'react-redux';

import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import {HomeworkStatus} from 'common/enums';

import {
  changeHomeworkStatus,
  homeworkDoneNotification,
  homeworkCheckedNotification
} from '../actions';
import {HomeworkModal} from './HomeworkModal';
import './SendHomeworkNotificationModal.scss';

interface Props extends WrappedComponentProps {
  onHide: () => void;
  homeworkId?: string;
  courseInstanceId?: number;
  studentTeacherId?: string;
}

const messages = defineMessages({
  studentSent: {
    id: 'Homework.Modal.ChangeStatus.Toast.Sent.Student'
  },
  studentChangeStatusFailed: {
    id: 'Homework.Modal.ChangeStatus.Toast.ChangeStatusFailed.Student'
  },
  studentSendFailed: {
    id: 'Homework.Modal.ChangeStatus.Toast.SendFailed.Student'
  },
  teacherSent: {
    id: 'Homework.Modal.ChangeStatus.Toast.Sent.Teacher'
  },
  teacherChangeStatusFailed: {
    id: 'Homework.Modal.ChangeStatus.Toast.ChangeStatusFailed.Teacher'
  },
  teacherSendFailed: {
    id: 'Homework.Modal.ChangeStatus.Toast.SendFailed.Teacher'
  }
});

const SendHomeworkNotificationModal: React.FC<Props> = ({
  intl,
  homeworkId,
  courseInstanceId,
  onHide,
  studentTeacherId
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const role = useSelector((state: AppState) => state.user.role);
  const show = !!homeworkId && !!courseInstanceId;
  const dispatch = useDispatch();
  const sendNotification = useCallback(
    async (comment: string) => {
      setLoading(true);

      try {
        await dispatch(
          changeHomeworkStatus(
            courseInstanceId!,
            homeworkId!,
            role === 'student' ? HomeworkStatus.DONE : HomeworkStatus.CHECKED
          )
        );
      } catch {
        setLoading(false);
        toastr.error(
          '',
          intl.formatMessage(
            role === 'student'
              ? messages.studentChangeStatusFailed
              : messages.teacherChangeStatusFailed
          )
        );
        return;
      }
      try {
        const homeworkLink = studentTeacherId
          ? `/room/${studentTeacherId}/course/${courseInstanceId}/homework/${homeworkId}`
          : undefined;
        role === 'student'
          ? await dispatch(homeworkDoneNotification(courseInstanceId!, comment, homeworkLink))
          : await dispatch(homeworkCheckedNotification(courseInstanceId!, comment, homeworkLink));
        onHide();
        toastr.success(
          '',
          intl.formatMessage(role === 'student' ? messages.studentSent : messages.teacherSent)
        );
      } catch {
        toastr.error(
          '',
          intl.formatMessage(
            role === 'student' ? messages.studentSendFailed : messages.teacherSendFailed
          )
        );
        return;
      } finally {
        setLoading(false);
      }
    },
    [dispatch, courseInstanceId, homeworkId, role, intl, studentTeacherId, onHide]
  );

  const headerTest =
    role === 'student' ? (
      <FormattedMessage id="Homework.Modal.ChangeStatus.Title.Student" />
    ) : (
      <FormattedMessage id="Homework.Modal.ChangeStatus.Title.Teacher" />
    );

  return (
    <HomeworkModal
      show={!!show}
      headerText={headerTest}
      isLoading={isLoading}
      onAccept={sendNotification}
      onHide={onHide}
    />
  );
};

export default injectIntl(SendHomeworkNotificationModal);
